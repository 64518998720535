import { NextPageWithLayout } from "./_app.page";

import { NotFoundScreen } from "src/components/NotFoundScreen";
import { UnauthenticatedLayout } from "src/components/layout/UnauthenticatedLayout";

const Page: NextPageWithLayout = () => {
  return <NotFoundScreen />;
};

Page.getLayout = (page) => (
  <UnauthenticatedLayout pageTitle="ページが見つかりませんでした">
    {page}
  </UnauthenticatedLayout>
);

export default Page;
