import { Box, Flex, Text } from "@chakra-ui/react";
import Head from "next/head";
import { FC, ReactNode } from "react";

import { Header } from "../Header/Header";

type Props = {
  pageTitle?: string;
  children: ReactNode;
};

export const UnauthenticatedLayout: FC<Props> = ({ pageTitle, children }) => {
  return (
    <>
      <Head>
        {pageTitle ? (
          <title>Speria | {pageTitle}</title>
        ) : (
          <title>Speria</title>
        )}
      </Head>

      <Flex direction="column" minHeight="100vh">
        <Header />

        <Box flex="1" p={4}>
          {children}
        </Box>

        <Box as="footer" p={4} textAlign="center">
          <Text fontSize="sm">Speria, Inc.</Text>
        </Box>
      </Flex>
    </>
  );
};
